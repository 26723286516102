export default {
  "navBar.lang": "语言",
  "layout.user.link.help": "帮助",
  "layout.user.link.privacy": "隐私",
  "layout.user.link.terms": "条款",
  "validation.email.required": "请输入邮箱地址！",
  "validation.email.wrong-format": "邮箱地址格式错误！",
  "validation.password.required": "请输入密码！",
  "validation.password.twice": "两次输入的密码不匹配!",
  "validation.password.strength.msg": "请至少输入 6 个字符。请不要使用容易被猜到的密码。",
  "validation.password.strength.strong": "强度：强",
  "validation.password.strength.medium": "强度：中",
  "validation.password.strength.short": "强度：太短",
  "validation.confirm-password.required": "请确认密码！",
  "validation.phone-number.required": "请输入手机号！",
  "validation.phone-number.wrong-format": "手机号格式错误！",
  "validation.verification-code.required": "请输入验证码！",
  "validation.title.required": "请输入标题",
  "validation.date.required": "请选择起止日期",
  "validation.goal.required": "请输入目标描述",
  "validation.standard.required": "请输入衡量标准",
  "form.optional": "（选填）",
  "form.submit": "提交",
  "form.save": "保存",
  "form.email.placeholder": "邮箱",
  "form.password.placeholder": "至少6位密码，区分大小写",
  "form.confirm-password.placeholder": "确认密码",
  "form.phone-number.placeholder": "位手机号",
  "form.verification-code.placeholder": "验证码",
  "form.title.label": "标题",
  "form.title.placeholder": "给目标起个名字",
  "form.date.label": "起止日期",
  "form.date.placeholder.start": "开始日期",
  "form.date.placeholder.end": "结束日期",
  "form.goal.label": "目标描述",
  "form.goal.placeholder": "请输入你的阶段性工作目标",
  "form.standard.label": "衡量标准",
  "form.standard.placeholder": "请输入衡量标准",
  "form.client.label": "客户",
  "form.client.label.tooltip": "目标的服务对象",
  "form.client.placeholder": "请描述你服务的客户，内部客户直接 @姓名／工号",
  "form.invites.label": "邀评人",
  "form.invites.placeholder": "请直接 @姓名／工号，最多可邀请 5 人",
  "form.weight.label": "权重",
  "form.weight.placeholder": "请输入",
  "form.public.label": "目标公开",
  "form.public.label.help": "客户、邀评人默认被分享",
  "form.public.radio.public": "公开",
  "form.public.radio.partially-public": "部分公开",
  "form.public.radio.private": "不公开",
  "form.publicUsers.placeholder": "公开给",
  "form.publicUsers.option.A": "同事甲",
  "form.publicUsers.option.B": "同事乙",
  "form.publicUsers.option.C": "同事丙",
  "component.globalHeader.search": "站内搜索",
  "component.globalHeader.search.example1": "搜索提示一",
  "component.globalHeader.search.example2": "搜索提示二",
  "component.globalHeader.search.example3": "搜索提示三",
  "component.globalHeader.help": "使用文档",
  "component.globalHeader.notification": "通知",
  "component.globalHeader.notification.empty": "你已查看所有通知",
  "component.globalHeader.message": "消息",
  "component.globalHeader.message.empty": "您已读完所有消息",
  "component.globalHeader.event": "待办",
  "component.globalHeader.event.empty": "你已完成所有待办",
  "component.noticeIcon.clear": "清空",
  "component.noticeIcon.cleared": "清空了",
  "component.noticeIcon.empty": "暂无数据",
  "menu.home": "首页",
  "menu.reservation": "预约登记",
  "menu.reservation.reservation-list": "预约列表",
  "menu.reservation.reservation-edit": "编辑预约",
  "menu.reservation.application-list": "已缴费列表",

  "app.login.message-invalid-credentials": "账户或密码错误（admin/888888）",
  "app.login.message-invalid-verification-code": "验证码错误",
  "app.login.tab-login-credentials": "账户密码登录",
  "app.login.tab-login-mobile": "手机号登录",
  "app.login.remember-me": "自动登录",
  "app.login.forgot-password": "忘记密码",
  "app.login.sign-in-with": "其他登录方式",
  "app.login.signup": "注册账户",
  "app.login.login": "登录",
  "app.register.register": "注册",
  "app.register.get-verification-code": "获取验证码",
  "app.register.sing-in": "使用已有账户登录",
  "app.register-result.msg": "你的账户：{email} 注册成功",
  "app.register-result.activation-email":
    "激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。",
  "app.register-result.back-home": "返回首页",
  "app.register-result.view-mailbox": "查看邮箱",
  "app.home.introduce": "介绍",
  "app.analysis.test": "工专路 {no} 号店",
  "app.analysis.introduce": "指标说明",
  "app.analysis.total-sales": "总销售额",
  "app.analysis.day-sales": "日销售额",
  "app.analysis.visits": "访问量",
  "app.analysis.visits-trend": "访问量趋势",
  "app.analysis.visits-ranking": "门店访问量排名",
  "app.analysis.day-visits": "日访问量",
  "app.analysis.week": "周同比",
  "app.analysis.day": "日同比",
  "app.analysis.payments": "支付笔数",
  "app.analysis.conversion-rate": "转化率",
  "app.analysis.operational-effect": "运营活动效果",
  "app.analysis.sales-trend": "销售趋势",
  "app.analysis.sales-ranking": "门店销售额排名",
  "app.analysis.all-year": "全年",
  "app.analysis.all-month": "本月",
  "app.analysis.all-week": "本周",
  "app.analysis.all-day": "今日",
  "app.analysis.search-users": "搜索用户数",
  "app.analysis.per-capita-search": "人均搜索次数",
  "app.analysis.online-top-search": "线上热门搜索",
  "app.analysis.the-proportion-of-sales": "销售额类别占比",
  "app.analysis.channel.all": "全部渠道",
  "app.analysis.channel.online": "线上",
  "app.analysis.channel.stores": "门店",
  "app.analysis.sales": "销售额",
  "app.analysis.traffic": "客流量",
  "app.analysis.table.rank": "排名",
  "app.analysis.table.search-keyword": "搜索关键词",
  "app.analysis.table.users": "用户数",
  "app.analysis.table.weekly-range": "周涨幅",
  "app.forms.basic.title": "基础表单",
  "app.forms.basic.description":
    "表单页用于向用户收集或验证信息，基础表单常见于数据项较少的表单场景。",
  "app.monitor.trading-activity": "活动实时交易情况",
  "app.monitor.total-transactions": "今日交易总额",
  "app.monitor.sales-target": "销售目标完成率",
  "app.monitor.remaining-time": "活动剩余时间",
  "app.monitor.total-transactions-per-second": "每秒交易总额",
  "app.monitor.activity-forecast": "活动情况预测",
  "app.monitor.efficiency": "券核效率",
  "app.monitor.ratio": "跳出率",
  "app.monitor.proportion-per-category": "各品类占比",
  "app.monitor.fast-food": "中式快餐",
  "app.monitor.western-food": "西餐",
  "app.monitor.hot-pot": "火锅",
  "app.monitor.waiting-for-implementation": "Waiting for implementation",
  "app.monitor.popular-searches": "热门搜索",
  "app.monitor.resource-surplus": "资源剩余",
  "app.monitor.fund-surplus": "补贴资金剩余",
  "app.settings.menuMap.basic": "基本设置",
  "app.settings.menuMap.security": "安全设置",
  "app.settings.menuMap.binding": "账号绑定",
  "app.settings.menuMap.notification": "新消息通知",
  "app.settings.basic.avatar": "头像",
  "app.settings.basic.change-avatar": "更换头像",
  "app.settings.basic.email": "邮箱",
  "app.settings.basic.email-message": "请输入您的邮箱!",
  "app.settings.basic.nickname": "昵称",
  "app.settings.basic.nickname-message": "请输入您的昵称!",
  "app.settings.basic.profile": "个人简介",
  "app.settings.basic.profile-message": "请输入个人简介!",
  "app.settings.basic.profile-placeholder": "个人简介",
  "app.settings.basic.country": "国家/地区",
  "app.settings.basic.country-message": "请输入您的国家或地区!",
  "app.settings.basic.geographic": "所在省市",
  "app.settings.basic.geographic-message": "请输入您的所在省市!",
  "app.settings.basic.address": "街道地址",
  "app.settings.basic.address-message": "请输入您的街道地址!",
  "app.settings.basic.phone": "联系电话",
  "app.settings.basic.phone-message": "请输入您的联系电话!",
  "app.settings.basic.update": "更新基本信息",
  "app.settings.security.strong": "强",
  "app.settings.security.medium": "中",
  "app.settings.security.weak": "弱",
  "app.settings.security.password": "账户密码",
  "app.settings.security.password-description": "当前密码强度：",
  "app.settings.security.phone": "密保手机",
  "app.settings.security.phone-description": "已绑定手机：",
  "app.settings.security.question": "密保问题",
  "app.settings.security.question-description": "未设置密保问题，密保问题可有效保护账户安全",
  "app.settings.security.email": "备用邮箱",
  "app.settings.security.email-description": "已绑定邮箱：",
  "app.settings.security.mfa": "MFA 设备",
  "app.settings.security.mfa-description": "未绑定 MFA 设备，绑定后，可以进行二次确认",
  "app.settings.security.modify": "修改",
  "app.settings.security.set": "设置",
  "app.settings.security.bind": "绑定",
  "app.settings.binding.taobao": "绑定淘宝",
  "app.settings.binding.taobao-description": "当前未绑定淘宝账号",
  "app.settings.binding.alipay": "绑定支付宝",
  "app.settings.binding.alipay-description": "当前未绑定支付宝账号",
  "app.settings.binding.dingding": "绑定钉钉",
  "app.settings.binding.dingding-description": "当前未绑定钉钉账号",
  "app.settings.binding.bind": "绑定",
  "app.settings.notification.password": "账户密码",
  "app.settings.notification.password-description": "其他用户的消息将以站内信的形式通知",
  "app.settings.notification.messages": "系统消息",
  "app.settings.notification.messages-description": "系统消息将以站内信的形式通知",
  "app.settings.notification.todo": "账户密码",
  "app.settings.notification.todo-description": "账户密码",
  "app.settings.open": "开",
  "app.settings.close": "关",
  "app.exception.back": "返回首页",
  "app.exception.description.403": "抱歉，你无权访问该页面",
  "app.exception.description.404": "抱歉，你访问的页面不存在",
  "app.exception.description.500": "抱歉，服务器出错了",
  "app.result.error.title": "提交失败",
  "app.result.error.description": "请核对并修改以下信息后，再重新提交。",
  "app.result.error.hint-title": "您提交的内容有如下错误：",
  "app.result.error.hint-text1": "您的账户已被冻结",
  "app.result.error.hint-btn1": "立即解冻",
  "app.result.error.hint-text2": "您的账户还不具备申请资格",
  "app.result.error.hint-btn2": "立即升级",
  "app.result.error.btn-text": "返回修改",
  "app.result.success.title": "提交成功",
  "app.result.success.description":
    "提交结果页用于反馈一系列操作任务的处理结果， 如果仅是简单操作，使用 Message 全局提示反馈即可。 本文字区域可以展示简单的补充说明，如果有类似展示 “单据”的需求，下面这个灰色区域可以呈现比较复杂的内容。",
  "app.result.success.operate-title": "项目名称",
  "app.result.success.operate-id": "项目 ID：",
  "app.result.success.principal": "负责人：",
  "app.result.success.operate-time": "生效时间：",
  "app.result.success.step1-title": "创建项目",
  "app.result.success.step1-operator": "曲丽丽",
  "app.result.success.step2-title": "部门初审",
  "app.result.success.step2-operator": "周毛毛",
  "app.result.success.step2-extra": "催一下",
  "app.result.success.step3-title": "财务复核",
  "app.result.success.step4-title": "完成",
  "app.result.success.btn-return": "返回列表",
  "app.result.success.btn-project": "查看项目",
  "app.result.success.btn-print": "打印",
  "app.setting.pagestyle": "整体风格设置",
  "app.setting.pagestyle.dark": "暗色菜单风格",
  "app.setting.pagestyle.light": "亮色菜单风格",
  "app.setting.content-width": "内容区域宽度",
  "app.setting.content-width.fixed": "定宽",
  "app.setting.content-width.fluid": "流式",
  "app.setting.themecolor": "主题色",
  "app.setting.themecolor.dust": "薄暮",
  "app.setting.themecolor.volcano": "火山",
  "app.setting.themecolor.sunset": "日暮",
  "app.setting.themecolor.cyan": "明青",
  "app.setting.themecolor.green": "极光绿",
  "app.setting.themecolor.daybreak": "拂晓蓝（默认）",
  "app.setting.themecolor.geekblue": "极客蓝",
  "app.setting.themecolor.purple": "酱紫",
  "app.setting.navigationmode": "导航模式",
  "app.setting.sidemenu": "侧边菜单布局",
  "app.setting.topmenu": "顶部菜单布局",
  "app.setting.fixedheader": "固定 Header",
  "app.setting.fixedsidebar": "固定侧边菜单",
  "app.setting.fixedsidebar.hint": "侧边菜单布局时可配置",
  "app.setting.hideheader": "下滑时隐藏 Header",
  "app.setting.hideheader.hint": "固定 Header 时可配置",
  "app.setting.othersettings": "其他设置",
  "app.setting.weakmode": "色弱模式",
  "app.setting.copy": "拷贝设置",
  "app.setting.copyinfo": "拷贝成功，请到 src/defaultSettings.js 中替换默认配置",
  "app.setting.production.hint":
    "配置栏只在开发环境用于预览，生产环境不会展现，请拷贝后手动修改配置文件",
};
