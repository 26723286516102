export function getAuthority(str) {
  const authorityString =
    typeof str === "undefined" ? localStorage.getItem("user-authority") : str;

  let authority;
  try {
    authority = JSON.parse(authorityString);
  } catch (e) {
    authority = authorityString;
  }
  if (typeof authority === "string") {
    return [authority];
  }
  return authority || ["null"];
}

export async function setAuthority(authority) {
  const proAuthority = typeof authority === "string" ? [authority] : authority;
  return await localStorage.setItem("user-authority", JSON.stringify(proAuthority));
}
