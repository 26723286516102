import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/zjghpt',
    name: 'zjghpt',
    text: '入驻心理师',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/mobile/model.js').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () => import('../mobile/zjghpt'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
            .default,
        })
      : require('../mobile/zjghpt').default,
    exact: true,
  },
  {
    path: '/settlePsychologist',
    name: 'settlePsychologist',
    text: '入驻心理师',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/mobile/model.js').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () => import('../mobile/settlePsychologist'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
            .default,
        })
      : require('../mobile/settlePsychologist').default,
    exact: true,
  },
  {
    path: '/createByCustomer',
    name: 'createByCustomer',
    text: '创建预约',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/mobile/model.js').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () => import('../mobile/AddCustomerForm'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
            .default,
        })
      : require('../mobile/AddCustomerForm').default,
    exact: true,
  },
  {
    path: '/editByCustomer',
    name: 'editByCustomer',
    text: '创建预约',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/mobile/model.js').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () => import('../mobile/EditCustomerForm'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
            .default,
        })
      : require('../mobile/EditCustomerForm').default,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/BasicLayout'),
          LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    name: '首页',
    routes: [
      {
        path: '/home',
        name: 'home',
        text: '首页',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/home/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../home'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../home').default,
        exact: true,
      },
      {
        path: '/aboutAncare',
        name: 'aboutAncare',
        text: '关于安肯',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/aboutAncare/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../aboutAncare'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../aboutAncare').default,
        exact: true,
      },
      {
        path: '/newsAncare',
        name: 'newsAncare',
        text: '安肯新闻',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/newsAncare/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../newsAncare'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../newsAncare').default,
        exact: true,
      },
      {
        path: '/offlineMechanism',
        name: 'offlineMechanism',
        text: '线下机构',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/offlineMechanism/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../offlineMechanism'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../offlineMechanism').default,
        exact: true,
      },
      {
        path: '/psychologicalService',
        name: 'psychologicalService',
        text: '心理服务',
        routes: [
          {
            path: '/psychologicalService/category',
            name: 'category',
            text: '心理中心',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/psychologicalService/category/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../psychologicalService/category'),
                  LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                    .default,
                })
              : require('../psychologicalService/category').default,
            exact: true,
          },
          {
            path: '/psychologicalService/article',
            name: 'article',
            text: '心理服务文章',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/psychologicalService/article/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../psychologicalService/article'),
                  LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                    .default,
                })
              : require('../psychologicalService/article').default,
            exact: true,
          },
        ],
      },
      {
        path: '/articles',
        name: 'articles',
        text: '案例/新闻',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/articles/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../articles'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../articles').default,
        exact: true,
      },
      {
        path: '/eap',
        name: 'eap',
        text: '案例/新闻',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/pages/eap/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../eap'),
              LoadingComponent: require('/Users/wuruiyang/Documents/ancare/ancare-official-website/src/components/PageLoading/index')
                .default,
            })
          : require('../eap').default,
        exact: true,
      },
      {
        path: '/',
        redirect: '/home',
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
