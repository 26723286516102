module.exports = {
  navTheme: "dark", // theme for nav menu
  primaryColor: "#1890FF", // primary color of ant design
  layout: "sidemenu", // nav menu position: sidemenu or topmenu
  contentWidth: "Fluid", // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  autoHideHeader: false, // auto hide header
  fixSiderbar: true, // sticky siderbar
  fontBaseSize: '12px',
  borderRadiusBase: '3px'
};
