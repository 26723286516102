import { query as queryUsers, queryCurrent } from "@/services/user";
import { setAuthority } from '@/utils/authority'
import { ROLE_KEY, ROLE_CODE, CLINIC_KEY, CLINIC_LOGO } from '@/utils/consts'
export default {
  namespace: "user",
  state: {
    list: [],
    currentUser: {},
    userAuthority: [],
    systemConfig: {},
    userPermissions: []
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: "save",
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const { data, code } = yield call(queryCurrent);
      console.log(data)
      let userRole = []
      if(data.role === '在线医助') {
        userRole.push('assiant')
      } else if(data.role === '线下导诊') {
        userRole.push('guidance')
      } else {
        userRole.push('admin')
      }
      if(code === 0) {
        let {rolePermissionVos} = data 
        let rols = localStorage.getItem(ROLE_KEY)
        //获取最新的logo
        let clinicId = localStorage.getItem(CLINIC_KEY);
        let logo = localStorage.getItem(CLINIC_LOGO);
        if(clinicId){
          data&&data.clinicResps.forEach((item)=>{
            if(clinicId === `${item.id}`){
              if(logo !== item.logo){
                localStorage.setItem(CLINIC_LOGO,item.logo);
                return;
              }
            }
          })
        }
        let codes = (rolePermissionVos.find(_ => _.roleId === Number(rols)) || {}).code
        localStorage.setItem(ROLE_CODE, codes)
        setAuthority(userRole)
        yield put({
          type: "updateState",
          payload: {
            userAuthority: userRole,
          },
        });
        yield put({
          type: "saveCurrentUser",
          payload: data,
        });

        

        if (data.clinicId) {
          sessionStorage.setItem('clinicId', data.clinicId)
        }
      }
      // const resConfig = yield call(queryConfigs, { type: 'SYSTEM_CONFIG_ITEM' });
      // if(resConfig && resConfig.code === 0) {
      //   const configData = {}
      //   if(resConfig.data && resConfig.data.length > 0) {
      //     resConfig.data.forEach(_ => configData[_.refKey] = _.refValue)
      //   }

      //   yield put({
      //     type: "updateState",
      //     payload: {
      //       systemConfig: configData
      //     },
      //   });
      // }
      return {
        userRole
      }
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.payload || {},
      };
    },
    saveUserAuthority(state, action) {
      return {
        ...state,
        userAuthority: action.payload || [],
      };
    },
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
