import request from '@/utils/request';
import { BASE_API, BASE_WENJUAN, WENJUAN_URL } from '@/utils/consts'

// const BASE_API = '/apid'

export function queryProvinces() {
  return request(`${BASE_API}/region/province/list`);
}

export function queryCities(data) {
  return request(`${BASE_API}/region/province/show/${data.id}`);
}

export function queryAreas(data) {
  return request(`${BASE_API}/region/city/show/${data.id}`);
}

export function queryStreets(data) {
  return request(`${BASE_API}/region/country/show/${data.id}`);
}

export function queryChannels(data) {
  return request(`${BASE_API}/channel/list`, {
    method: 'POST',
    data
  });
}

// 添加渠道
export function addChannels(data) {
  return request(`${BASE_API}/channel/create`, {
    method: 'POST',
    data
  });
}

// 编辑渠道
export function updateChannels(data) {
  return request(`${BASE_API}/channel/update`, {
    method: 'PUT',
    data
  });
}

export function lockChannels(data) {  
  return request(`${BASE_API}/channel/lock/${data.id}`, {
    method: 'PUT'
  });
}

export function queryRolesByName(data) {
  return request(`${BASE_API}/employee/list_by_role`, {
    data
  });
}

export function queryProblems(data) {
  return request(`${BASE_API}/problem/list/all`, {
    data
  });
}

export function queryDicts(data) {
  return request(`${BASE_API}/dictionary_item/list_by_type`, {
    method: 'POST',
    data: {
      type: data.type,
      pageNo: 1,
      pageSize: 1000
    }
  });
}

export function queryRootAgency() {
  return request(`${BASE_API}/organization/list_top_department`);
}

export function queryAgencyByPid(data) {
  return request(`${BASE_API}/organization/show/${data.pid}`);
}
export function queryClinicList(data) {
  return request(`${BASE_API}/clinic/selection_list`);
}

export function queryMemberList(data) {
  return request(`${BASE_API}/users/list_clinic_employee`, {
    method: 'POST',
    data
  })
}
// 获取所有的客户状态列表
export function queryCustomerStatusList(data) {
  return request(`${BASE_API}/customer/status/list`, {
    method: 'POST',
    data
  })
}
// 客服修改日志
export function operationCustomer(data) {
  return request(`${BASE_API}/operation_log/customer`, {
    method: 'POST',
    data
  })
}
// 客服初始化详情
export function operationInti(data) {
  return request(`${BASE_API}/operation_log/show//${data.id}`);
}

// 字典项查询
export function quertDictsItem(data) {
  return request(`${BASE_API}/dictionary_item/list_by_type`, {
    method: 'POST',
    data
  });
}

// 判断客户的手机唯一性
export function checkPersonalPhoneExist(data) {
  return request(`${BASE_API}/customer/is_phone_exist`, {
    data
  });
}

// 判断客户的微信号唯一性
export function checkPersonalWeixinIdExist(data) {
  return request(`${BASE_API}/customer/is_weixin_id_exist`, {
    method: 'POST',
    data
  });
}

//查询诊所相关角色人员
export function queryClinicEmployeeByRole(data) {
  return request(`${BASE_API}/employee/list_by_role`, {
    data
  })
}
// 获取诊所下的员工列表
export function queryClinicEmployee(data) {
  return request(`${BASE_API}/employee/list_by/${data.id}`)
}

// 升单详情 获取诊所下的员工列表
export function queryClinicEmployeeUpdate(data) {
  // return request(`${BASE_API}/employee/list_by/${data.id}`)
  //修改接口
  return request(`${BASE_API}/employee/list/created_by/${data.id}`)
}

// 客户姓名是否已经存在
export function checkPersonalNameExist(data) {
  return request(`${BASE_API}/customer/is_name_exist`, {
    method: 'POST',
    data
  });
}

// 查询诊所角色为心理顾问的员工列表
export function queryConsultantAll(data) {
  return request(`${BASE_API}/employee/list/consultant?clinicId=${data.clinicId}`)
}

// 获取H5端二维码
export function queryQestionnaire(data) {
  return request(`${BASE_WENJUAN}/config/questionnaire/getQrCode?orgId=${data.orgId}&userId=${data.userId}&userName=${data.userName}`)
}

// 获取H5语义
export function queryGenerateSemantics(data) {
  return request(`${BASE_WENJUAN}/config/questionnaire/h5/generateSemantics/${data.userId}?orgId=${data.orgId}`)
}


//分页查询订单下的所有已完成预约
export function getAllReservation(data) {
  return request(`${BASE_API}/reservation/finished_reservation_items/${data.orderId}`,{data})
}

//查询应用程序模块列表
export function getApplicationList(){
  return request(`${BASE_API}/application/list`,{method:'GET'});
}