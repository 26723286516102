import request from "@/utils/request";
import { BASE_API } from '@/utils/consts'

export async function queryCurrent() {
  return request(`${BASE_API}/users/show`);
}

export function login(data) {
  return request(`${BASE_API}/users/clinic/sign_in`, {
    data,
    method: 'post'
  });
}

export function changePwd(data) {
  return request(`${BASE_API}/users/update_my_password`, {
    data,
    method: 'put'
  });
}


//设置诊所默认角色
export function setDefaultRole(data) {
  return request(`${BASE_API}/role/application/clinic/setup_default_role`, {
    data,
    method: 'post'
  });
}

//获取图片验证码